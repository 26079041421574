.search-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    margin-bottom: 2rem;
}

.search-box {
    width: 50%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    /* padding: 20px; */
    padding: 2% 5%;
}

.grid-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: #007DC1;
    border-radius: 10px;
}

.grid-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.grid-item .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 10px;
}

.grid-item:hover .overlay {
    opacity: 1;
}
@media (max-width:900px){
    .grid-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width:500px){
    .grid-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .search-container {
        flex-direction: column;
        gap: 1rem;
    }
    .search-box {
        width: 90%;
    }
}