.home_image_main{
  padding: 0% 10%;
  margin-top: 3.5rem;
}
.homeimage_box{
    display: flex;
   justify-content: space-between;
   align-items: flex-start;
   margin-top: 3rem;
   /* background-color: red; */
}
.homeimage_sm_box{
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}
.homeimage_img_box{
    width: 35%;
}
.homeimage_img_box img{
    mix-blend-mode:multiply;
    width: 100%;
    height: 250px;
    object-fit: contain;
}
.homeimage_xs_box {
    /* animation: fadeInOut 5s ease-in-out infinite; */
  }
.homeimage_xs_box h3{
   font-size: 1.4rem;
   font-weight: bold;
}
@keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @media(max-width: 767px) {
    /* .home_image_main{
      padding: 0% 5%;
    } */
    .homeimage_box{
      flex-direction: column-reverse;
      gap: 1rem;
  }
  .homeimage_sm_box{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* padding: 0rem .5rem; */
    gap: 2rem;
}
.homeimage_img_box{
  width: 100%;
}
.homeimage_xs_box h3{
  font-size: 1.1rem;
}
.homeimage_xs_box p{
  font-size: 1rem;
 }
 .homeimage_img_box img{
transform: rotate(270deg);
margin-bottom: 1rem;
}
}
  