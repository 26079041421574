.dash_stepper_box{
    display: flex;
    width: 100%;
}
.dash_stepper_box_left{
    padding:1rem 0rem 2rem 0rem;
    border-right: 1px solid #b6d5e4 ;
}
.dash_circle {
    width: 32px;
    height: 32px;
    line-height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #007DC1;
    color: #007DC1;
    background-color: white;
    cursor: pointer;
    z-index: 200;
   
  }
  .dash_selected .dash_circle {
    background-color: #007DC1;
    color: white;
    border-color: #007DC1;
  }
  
   .dash_circleWrapper{
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    margin-top: 1rem;
    padding-top: .5rem;
    padding-left: .5rem;
  }
  .dash_selected .dash_circleWrapper{
    border: 1px solid #007DC1;
    background-color: #f0faff;
    border-radius: 10px;
  }
  .dash_circleWrapper p{
    font-size: .9rem;
  }
  .dash_line{
    height: 42vh;
    background-color: #007DC1;
    width: 2px;
    position: absolute;
    top: 1rem;
    left: 7%;
}

.dash_stepper_box_right{
    padding: 2rem;
}
.dash_stepper_box_right_up{
    padding: 4rem 0rem 3rem 0rem;
}
.dash_stepper_box_right_up i{
    font-size: 2rem;
}
.dash_stepper_btn_container{
    margin-top: 10%;
    display: flex;
    justify-content: flex-end;
}
.dash_stepper_last_btn_container{
    margin-top: 10%;
    display: flex;
    justify-content: space-between;
}
.dash_stepper_btn_container_btn,.dash_stepper_last_btn_container_btn{
    font-size: 1rem;
    border: 1px solid #007DC1;
    background-color: white;
    color: #007DC1;
    font-weight: 600;
    padding: .3rem 1.5rem;
    border-radius: 25px;
}
.read_again_btn{
    border: none;
    outline: none;
    background-color: white;
}
.read_again_btn span{
    margin-left: .5rem;
}
@media (max-width:1680px){
    .dash_line{
        height: 55vh; 
        color: black;
}
}

@media (max-width:870px){
    
    .dash_stepper_box_right_up i, .dash_stepper_box_right_up h4{
        font-size: 1.2rem;
        font-weight: bold;
    }
    .dash_stepper_box_right_up p{
        font-size: .8rem;
    }
}