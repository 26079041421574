.image_wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 10px;
    height: 60px;
    border: 1px solid #ddd;
    border-radius: 10px;
}

.image_wrapper img {
    width: 100px;
    height: 60px;
    object-fit: contain;
}