.report_dash_main_box h3, .branch_bar_box_head h3 {
    font-weight: bolder;
    text-align: left;
}

.upper_chart_box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.semi_donut_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem; /* Adjusted for spacing */
}
.semi_donut_box_p{
    font-weight: bold;
    font-size: .9rem;
}
.report_dash_semi_donut {
    --percentage: 0;
    --fill: #ff0;
    width: 200px; /* Adjusted from 150px */
    height: 100px; /* Adjusted from 75px */
    position: relative;
    color: #fff;
    font-size: 18px; /* Adjusted from 14px */
    font-weight: 600;
    overflow: hidden;
    color: var(--fill);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    box-sizing: border-box;
    margin-top: 2.5rem; /* Adjusted from 2rem */
}

.report_dash_semi_donut::after {
    content: '';
    width: 190px; /* Adjusted from 150px */
    height: 190px; /* Adjusted from 150px */
    border: 35px solid; /* Adjusted from 25px */
    border-color: #eaff99 #eaff99 rgb(119, 221, 119) rgb(119, 221, 119);
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    transform: rotate(calc(1deg * (-45 + var(--percentage) * 1.8)));
    animation: fillAnimation 1s ease-in;
}

@keyframes fillAnimation {
    0% {
        transform: rotate(-45deg);
    }

    50% {
        transform: rotate(135deg);
    }
}
.branch_bar_box{
    margin-top: 5rem;
}
.branch_bar_box_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}
@media (max-width: 475px) {
    .upper_chart_box {
        flex-direction: column;
        align-items: center;
    }
    .branch_bar_box_head {
        flex-direction: column;
        align-items: flex-start;
    }
    .semi_donut_box {
        width: 100%; /* Adjust width for better responsiveness */
        margin-bottom: 1rem; /* Adjust spacing */
    }
    .report_dash_semi_donut {
        width: 150px; /* Adjusted size for smaller screens */
        height: 75px; /* Adjusted size for smaller screens */
    }
    .report_dash_semi_donut::after {
        width: 150px; /* Adjusted size for smaller screens */
        height: 150px; /* Adjusted size for smaller screens */
        border-width: 25px; /* Adjusted border width for smaller screens */
    }
    .branch_bar_box_head button {
        margin-top: 0.5rem; /* Add spacing between buttons */
    }
}
