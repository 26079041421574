.rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .rating .fa-star {
    font-size: 24px;
    margin-right: 5px;
    cursor: pointer;
    transition: color 0.2s;
  }
  
  .rating .fa-star.gold {
    color: gold;
  }
  .project_setup1_content textarea {
    width: 100%;
    background-color: #ffffff;
    font-size: 14px;
    padding: 15px 20px;
    color: black;
    outline: none;
    border: 1px solid #dddddd;
    border-radius: 5px;
}

.project_setup1_content textarea:focus {
    border-color: rgb(77, 215, 77);
}
.preview_section_outer{
  margin: 5rem 0rem;
}
.preview_sec_main{
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;
}

@media (max-width:1100px) {
  .preview_sec_main{
    width: 90%;
  }
}

  