.homepage_brand_heading{
    text-align: center;
    margin-top: 5rem;
   
}
.homepage_brand_heading h5{
    font-size: 1.1rem;
    font-weight: bold;
}
.homepage_brand{
    padding: 2% 15%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.homepage_brand_box{
    box-shadow: 0px 0px 5px rgb(219, 215, 215);
    padding: 0;
}
.homepage_brand_img,.homepage_brand_text{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* .homepage_brand_img{
    height: 250px;
} */
.homepage_brand_text{
    height: 320px; 
}
.homepage_brand_img img{
    width: 100%;
    /* height: 250px; */
    /* object-fit: cover; */
}

/* .homepage_brand_text{
    margin-top: 1rem;
} */
.homepage_brand_text p{
    height: 130px;
    height: 170px;
    overflow: auto;
    font-size: 15px;
    font-weight: 400;
    line-height: 170%;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}
.homepage_brand_text p::-webkit-scrollbar{
    width: 5px;
}
.homepage_brand_text a{
    text-decoration: none;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    margin-left: 1rem;
    display: flex;
    justify-content: flex-start;
    gap:.6rem;
    align-items: center;
}
.homepage_brand_text a span:hover{
   text-decoration: underline;
   text-underline-offset: 10px;
}
.homepage_brand_text img{
    width: 120px;
    height: 50px;
    object-fit: contain;
}
@media(max-width: 767px) {
    .homepage_brand_container{
        margin-bottom: 1rem;
    }
    .homepage_brand {
        flex-direction: column;
        padding: 2% 10%;
    }
    .homepage_brand_text{
        height: 270px;
    }
    .homepage_brand_text p{
        height: auto;
    }
    .homepage_brand_heading{
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}