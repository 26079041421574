.store_brow_main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.store_brow_left_box{
    width: 60%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.store_brow_right_box{
    width: 35%;

}
.dwnload_container{
    position: relative;
}
.year-list{
    position: absolute;
   box-shadow: 0px 0px 5px rgb(134, 134, 134);
   border-radius: 5px;
    top: 4.5rem;
    width: 100%;
    padding-left: 0rem;
    background-color: white;
    
}
.year-list a{
    color: black;
    font-size: .9rem;
}
.year-item{
    width: 100%;
    padding: .5rem 1rem;
    cursor: pointer;
    font-weight: bold;
}

.year-item:hover{
    background-color: #f1eeee;
}
td .rating-average{
    background-color: "#ffff99";
}


.table thead tr.rating-na,
.table tbody tr.rating-na,
.table tfoot tr.rating-na,
.table thead tr > td.rating-na,
.table tbody tr > td.rating-na,
.table tfoot tr > td.rating-na,
.table thead tr > th.rating-na,
.table tbody tr > th.rating-na,
.table tfoot tr > th.rating-na {
	background-color: #F1F1F1;
}

.table thead tr.rating-bad,
.table tbody tr.rating-bad,
.table tfoot tr.rating-bad,
.table thead tr > td.rating-bad,
.table tbody tr > td.rating-bad,
.table tfoot tr > td.rating-bad,
.table thead tr > th.rating-bad,
.table tbody tr > th.rating-bad,
.table tfoot tr > th.rating-bad {
	background-color:#ffc299;
}

.table thead tr.rating-poor,
.table tbody tr.rating-poor,
.table tfoot tr.rating-poor,
.table thead tr > td.rating-poor,
.table tbody tr > td.rating-poor,
.table tfoot tr > td.rating-poor,
.table thead tr > th.rating-poor,
.table tbody tr > th.rating-poor,
.table tfoot tr > th.rating-poor {
	background-color: #ffeb99;
}

.table thead tr.rating-average,
.table tbody tr.rating-average,
.table tfoot tr.rating-average,
.table thead tr > td.rating-average,
.table tbody tr > td.rating-average,
.table tfoot tr > td.rating-average,
.table thead tr > th.rating-average,
.table tbody tr > th.rating-average,
.table tfoot tr > th.rating-average {
	background-color: #ff9;
}

.table thead tr.rating-good,
.table tbody tr.rating-good,
.table tfoot tr.rating-good,
.table thead tr > td.rating-good,
.table tbody tr > td.rating-good,
.table tfoot tr > td.rating-good,
.table thead tr > th.rating-good,
.table tbody tr > th.rating-good,
.table tfoot tr > th.rating-good {
	background-color: #eaff99;
}

.table thead tr.rating-excellent,
.table tbody tr.rating-excellent,
.table tfoot tr.rating-excellent,
.table thead tr > td.rating-excellent,
.table tbody tr > td.rating-excellent,
.table tfoot tr > td.rating-excellent,
.table thead tr > th.rating-excellent,
.table tbody tr > th.rating-excellent,
.table tfoot tr > th.rating-excellent {
	background-color:#c1ff99;
}

/* ----------------- FOR STOR BROWSER DETAILS PAGE ------------------------------- */
.tab_contaioner{
    width: 100%;
    display: flex;
    margin-top: 3rem;
    margin-bottom: 3rem;
}
.custom-tabs {
    display: flex;
    justify-content: flex-start;
    width: 50%;
}
.remaining_tab{
    border-bottom: 1px solid #ddd;
    width: 50%;
}

@media (max-width: 650px) {
    .store_brow_left_box, .store_brow_right_box {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        gap: 0rem;
    }

    .store_brow_main {
        flex-direction: column;
    }

    .store_brow_left_box, .store_brow_right_box {
        margin-bottom: 1rem;
    }
.download_btn{
    width: 40%;
}
   
}
@media (max-width: 420px){
    .custom-tabs {
        width: 100%;
    } 
    .remaining_tab{
        width: 0%;
    }
}