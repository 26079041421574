.pricing_heading{
    background-color: #eff7eb;
    padding: 2rem;
}

  
  .try-button {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background-color: rgb(0, 125, 193);
    font-weight: bold;
  }
.head_box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}  
.head_box h5{
    font-weight: bold;
}

/* IMPORTANT : the below part is to make sure even if there is a big sentence in a specific row, then for that structure doesn't get affected rather the big sentence will break and fit within provided space*/
.pricing_table{
  table-layout: fixed;
  width: 100%;
}
.pricing_table th,td{
  word-wrap: break-word;
}
/* ------------------------------------- */