.frequent_heading h1{
    font-size: 2.4rem;
}
.frequently_main_container{
    display: flex;
    justify-content: flex-start;
    gap: 3rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
    margin-left: 10%;
}
.frequently_accordion{
    width: 50%;
}
.frequently_video{
    width: 40%;
}
.frequently_video iframe{
    width: 100%;
    height: 310px;
}
@media (max-width: 1200px) {
    .frequently_main_container{
        margin-left: 5%;
    }  
}
@media (max-width: 850px) {
    .frequently_main_container{
       flex-direction: column;
    }  
    .frequently_accordion{
        width: 95%;
    }
    .frequently_video{
        width: 95%;
    }
}
@media (max-width: 600px) {
    .frequent_heading h1{
        font-size: 1.8rem;
    }
}