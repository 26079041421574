.nav_bar {
    width: 100%;
    height: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10000;
    background-color: white;
    box-shadow: 0px 0px 5px rgb(197, 194, 194);
    font-size: .9rem;
    /* padding-left: 3% ; */
    /* position: relative; */
    position: fixed;
}

.nav_bar_main {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.logo_img_main{
    width: 120px;
}
@media (max-width: 991px) {
    .nav_bar .logo a img {
        max-width: 100%;
    }

}

.menu-nav {
    display: flex;
    align-items: center;
}

.nav-item {
    position: relative;
}

.nav-link {
    /* font-size: 14px; */
    font-size: 1rem;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
}


.nav-item a {
    color: black;
}

.nav-item a:hover {
    color: black;
    text-decoration: underline;
    text-underline-offset: 8px;

}
.product_dropdown,.logout_dropdown{
    width: 300px;
    border: 1px solid rgb(203, 201, 201);
    background-color: white;
    position: absolute;
    top: 2.3rem;
    left: -20px;
    border-radius: 10px;
    padding: .8rem;
    padding-left: 2rem;
    cursor: pointer;
   visibility: hidden;
}
.logout_dropdown{
    width: 200px;
}
.product_dropdown span{
    text-decoration: none;
    color: black;
    font-size: 1rem;
}
.product_head:hover > ul{
   visibility: visible;
}
.head_dropdown:hover > ul{
    visibility: visible;
 } 
.logout_dropdown .dropdown_list{
    font-weight: 600;
    margin-bottom: .5rem;
    font-size: .9rem;
    cursor: pointer;
}
.dropdown_list {
    margin-bottom: .7rem;
}
.dropdown_list a,.dropdown_list span{
    text-decoration: none;
    /* font-size: 1rem; */
    font-size: 14px;
}
.dropdown_list a:hover, .dropdown_list span:hover{
    text-decoration: underline;
    text-underline-offset: 8px;
}
.search-signin {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
}
.search-signin input::placeholder {
    font-size: .9rem;
}
/* .apply-btn {
    margin-left: 50px;
    margin-top: -1px;
} */
.apply-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .8rem;
}
.apply-btn .sign-in {
    font-size: .9rem;
    color: black;
    width: 110px;
    height: 40px;
    /* text-align: center; */
    padding: 11px;
    padding-left: 0px;
    border-radius: 0 5px 5px 0;
    text-decoration: none;
}
.sign-in{
    white-space: nowrap;
}
.fa-bars{
    display: none;
    font-size: 1.5rem;
    padding-left: 10%;
}
.logosmall{
    display: none;
    padding-right: 3%;
}
.sidebar_nav_menu_container{
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    height: 100vh;
    position: fixed;
    top: 0;
    right: -100%;
    transition: 850ms;
    display: none;
}
.sidebar_nav_menu {
    background-color: white;
    box-shadow: 0px 0px 5px black;
    /* width: 75%; */
    width: 80%;
    height: 100vh;
      overflow: scroll;
    display: flex;
    justify-content: center;
    gap: 1rem;
    position: fixed;
    top: 0;
    /* right: -100%; */
    left: -100%;
    transition: 850ms;
  }
  .sidebar_nav_menu.active, .sidebar_nav_menu_container.active {
    left: 0;
    /* right: 0; */
    transition: 350ms;
  }
  .sidebar_nav_menu_items {
    width: 100%;
  }
  .sidebar_item_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .sidebar_nav_items{
    width: 100%;
    color:var(--bg-color);
    font-weight: bold;
    padding: 1rem;
    padding-left: 0rem;
    border-bottom: 1px solid rgb(231, 229, 229);
    text-decoration: none;
    cursor: pointer;
  }
  .sidebar_nav_items_li{
    /* width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: center; */
    margin-right: 12rem;
  }
  .sidebar_nav_items_li_resource{
    margin-right: 12.4rem;
  }
  .sidebar_nav_items:hover{
   color: #35bdd8;
  }
  .sidebar_nav_items_a{
    text-decoration: none;
    color: black;
    font-size: .8rem;
  }
  .sidebar_close_toggle {
    background-color: white;
    width: 100%;
    height: 80px;
    font-size: 1.5rem;
    color: var(--bg-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
.templet_dropdown{
    position: fixed;
    background-color: white;
    height: 100vh;
    top: 4rem;
    width: 100%;
    padding: 2.5rem;
}
.templet_dropdown_smallscrn{
   display: none;
}
.template_row{
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
    display: grid;
    grid-template-columns: repeat(4,1fr);
    column-gap: 1.8rem;
}
.template_row_small{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    color: black;
}
.template_box{
    background-color: white;
    padding: 2rem 1rem 1rem 1rem;
    border-radius: 10px;
    cursor: pointer;
}
.template_box:hover{
    background-color: #eff7eb;
}
.template_box h2{
    font-size: 1rem;
    font-weight: bold;
}
.template_box p{
    font-size: .8rem;
}
.template_box i{
    margin-right: .5rem;
}
.template_down{
    margin-top: 3rem;
    padding: 0rem 1rem;
    font-weight: bold;
}
.template_down a{
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.template_down i{
    margin-right: .5rem;
}
  @media (max-width: 870px) {
    .templet_dropdown{
        display: none;
    }
    .templet_dropdown_smallscrn{
        display: block;
    }
   
    .fa-bars,.logosmall,.sidebar_nav_menu_container{
        display: block;
    }
    .search-signin,.nav_lists, .logo {
        display: none;
    }
    .sidebar_nav_items_li{
        margin-right: 15.5rem;
    }
    .sidebar_nav_items_li_resource{
        margin-right: 15.9rem;
      } 
}
@media (max-width: 530px) {
    .sidebar_nav_items_li{
        margin-right: 11.5rem;
    }
    .sidebar_nav_items_li_resource{
        margin-right: 11.9rem;
      } 
      .template_row_small{
        grid-template-columns: repeat(1,1fr);
      }
}
@media (max-width: 450px) {
    .sidebar_nav_items_li{
        margin-right: 9.5rem;
    }
    .sidebar_nav_items_li_resource{
        margin-right: 9.9rem;
      } 
}
@media (max-width: 390px) {
    .sidebar_nav_items_li{
        margin-right: 7.5rem;
    }
    .sidebar_nav_items_li_resource{
        margin-right: 7.9rem;
      } 
}
@media (max-width: 350px) {
    .sidebar_nav_items_li{
        margin-right: 5.5rem;
    }
    .sidebar_nav_items_li_resource{
        margin-right: 5.9rem;
      } 
}