.ai_main_box{
    margin-top: 2rem;
}
.ai_upper_box_main{
    margin-top: 2rem;
    border-top: 1px solid #ddd;
    padding-top: 3rem;
    padding-bottom: 3rem;
}
.ai_lower_main_box{
    border-top: 1px solid #ddd;
    padding: 3rem 0rem;
}

.upper_table h1{
    font-size: 2rem;
}
.upper_table span{
    font-size: 1.3rem;
    color: gray;
}
.ai_lower_part_head h2{
   font-size: 1.1rem;
   border-width: 1px;
   border-style: solid;
   padding: 1rem;
   border-radius: 5px;
}
.head_neg{
    background-color: #f2dede;
    color: #a94442;
    border-color: #ebccd1;
}
.head_neu{
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}
.head_posi{
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}
.ai_lower_part_head >p{
    text-align: center;
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;
}
.ai_lower_part_head >p span {
    color: gray;
}
.ai_view_report_btn{
    padding: 4px 8px;
    margin-left: 1rem;
    position: absolute;
    right: 0;
    text-decoration: none;
    font-size: .8rem;
    color: #337ab7;
    font-weight: bold;
}
.ai_summary_box{
	padding: 1rem ;
	box-shadow: 0px 0px 5px rgb(169,169,169);
	border-radius: 10px;
	margin-bottom: 1rem;
	background-color: white;
    font-size: .8rem;
	}
	.ai_header_box{
		border-bottom: 1px solid #ddd;
		font-weight: bold;
		margin-bottom: 1rem;
	}
	.ai_see{
		color: #337ab7;
		font-size: 12px;
		text-decoration: underline;
		text-underline-offset: 3px;
		cursor: pointer;
	}
	.ai_loadmore{
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
		margin-top: 2rem;
	}
	.analyse_box{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.ai_modal_mainbox{
		display: grid;
		grid-template-columns: 24% 48% 24%;
		gap: 1rem;
	}
	.ai_modal_smbox{
		box-shadow: 0px 0px 5px rgb(169, 169, 169);
		padding: 1rem;
		border-radius: 5px;
        max-height: 450px;
	}
	.ai_modal_smbox h3{
		width: 100%;
		font-size: .9rem;
		font-weight: bold;
		padding-bottom: .5rem;
		border-bottom: 1px solid #ddd;
	}
	.ai_words_mainbox{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding-right: 1rem;
		
	}
	.bullet_box{
		max-height: 450px;
		overflow-y: scroll;
	    /* text-align: justify; */
		padding: .7rem 1rem 0rem 1rem;
		font-size: .9rem;
	}
	.bullet_box::-webkit-scrollbar {
		width: 8px; 
	}
	.bullet_box::-webkit-scrollbar-thumb {
		background-color: #ddd; 
		border-radius: 10px; 
	}
	.positive_mainbox, .negative_mainbox{
		max-height: 150px;
		overflow-y: scroll;
	}
	.ai_words_mainbox::-webkit-scrollbar {
		width: 5px; 
	}
	
	.positive_mainbox::-webkit-scrollbar-thumb {
		background-color: #ddd; 
		border-radius: 10px; 
	}
	.negative_mainbox::-webkit-scrollbar-thumb {
		background-color: #ddd; 
		border-radius: 10px; 
	}
	.ai_words_smallbox{
		background-color: #cff3cf;
		padding: .7rem .9rem .7rem .9rem;
		border-radius: 25px;
		color: #5cb85c;
		margin-bottom: 1rem;
	}
	.ai_negative{
		background-color: #f2c9c8;
		color: #d9534f;
	}
	.ai_words_smallbox p {
		font-size: .7rem;
	}
	.word_cloud_box{
		margin-top: 3rem;
		height: 150px;
		width: "100%";
		 /* background-color: #000; */
	}
	.aiinsight_storedetail{
		position: absolute;
		top: 0;
		left: 22rem;
	}
	@media screen and (max-width: 760px) {
		.ai_modal_mainbox {
			grid-template-columns: 1fr;
		}		
		.ai_modal_mainbox > * {
			width: 95%;
			margin: 0 auto;
		}
		.word_cloud_box{
			height: 100px;
		}
	}
	@media screen and (max-width: 830px) {
		.aiinsight_storedetail{
         top: 4rem;
		}
	}
	@media screen and (max-width: 450px) {
		.aiinsight_storedetail{
         top: 4rem;
		 left: 20rem;
		}
	}
	@media screen and (max-width: 410px) {
		.aiinsight_storedetail{
         top: 7rem;
		 left: 20rem;
		}
	}