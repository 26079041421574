.dash_tab_btn_container_div{
    /* margin-top: 7rem; */
    margin-top: 3rem;
}

.dash_tab_btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .3rem;
}

.tab,
.tab_2 {
    width: 25%;
    padding: .8rem 1.5rem;
    font-weight: bold;
    /* background: linear-gradient(to right, #066497, #007DC1);
    color: white; */
    background: white;
    color: black;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
    border: none;
}

.tab_2 {
    background: white;
    color: black;
}

.content {
    margin-top: 2rem;
    padding: 1rem;
    display: none;
}

.tab_active {
     background: linear-gradient(to right, #066497, #007DC1);
    color: white;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
    width: 25%;
    padding: .8rem 1.5rem;
    font-weight: bold;
    border: none;
}

.display_content {
    display: block;
}

.table_border {
    border: 1px solid #E9E9E9;
}

.dash_tab_table_head th {
    background-color: #f0faff;
}
.dash_tab_table_head_green th{
    background-color: #bdd9bb;
}
.dash_tab_table td {
    font-size: .9rem;
}

.dash_tab_table a {
    text-decoration: none;
}
@media(max-width: 980px){
    .tab,.tab_2,.tab_active {
        width: 35%;
}
}
@media (max-width: 750px) {
    .dash_tab_btn_container_div{
        margin-top: 2rem;
    }
    .dash_tab_btn_container {
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
    }
  
    .tab,
    .tab_2,.tab_active {
      width: 100%;
      padding: 0.5rem 1rem; 
      font-weight: bold;
    }
    /* .dash_tab_table td {
      padding: 0.5rem 0.2rem; 
    } */
  }
  