.howit_works_home{
    margin-top: 5rem;
}
.homepage_sec_one_row{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2% 10%;
}

.popular_products h5,.howit_works h5 {
    text-align: center;
    margin-bottom: 2rem;
    /* font-size: 2rem; */
    font-size: 2.3rem;
    font-weight: bolder;
}
.popular_products p{
    text-align: center;
    font-size: 1rem;
}
.banner-btn-hwitwrks a,
.banner-btn-hwitwrks button {
    font-size: 14px;
    color: #fff;
    background-color: #009444;
    display: inline-block;
    /* height: auto !important; */
    text-transform: uppercase;
    font-weight: 500;
    word-spacing: 1px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
}
.popular_products_boxes_img{
    border-radius: 10px;
    height: 150px;
    position: relative;
}
.popular_products_boxes_img img{
    width: 100%;
    border-radius: 10px;
    height: 150px;
}
.popular_products_content{
    width: 100%;
    height: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
    background: rgba(255, 255, 255,.2);
    box-shadow: 0 5px 15px rgba(0,0,0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
   }
   .popular_products_content h5 {
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    margin-top: 1.5rem;
}
.popular_products_boxes p{
    margin-top: 1rem;
}
.popular_products_shop{
text-decoration: none;
color: black;
font-weight: bold;
}
.popular_products_shop span:hover{
    text-decoration: underline;
    text-underline-offset: 8px;
}
.popular_products_col:hover .popular_products_shop span {
    text-decoration: underline;
    text-underline-offset: 8px;
  }
  
.howitwrks_tellus{
    margin-top: 1rem;
    padding-left: 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 3rem;
}
.howitwrks_tellus_p{
    font-size: 1.1rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
    align-items: center;
}
.howitwrks_tellus button{
    font-size: 14px;
    color: #fff;
    background-color: #007DC1;
    text-transform: uppercase;
    font-weight: 500;
    word-spacing: 1px;
    letter-spacing: 1px;
    padding: 8px 50px;
    border: 2px solid white;
    border-radius: 5px;
    transition: .5s;
}
.howitwrks_tellus button:hover{
    border: 2px solid #007DC1;
    color: #007DC1;
    background-color: white;
}
.hw_it_wrk_carouse_img{
    height: 370px;
}
.popular_products_smlscrn{
    display: none;
}
.popular_products_col_smlscrn{
    box-shadow: 0px 0px 20px 2px #d8d6d6;
    border-radius: 10px;
    padding-bottom: .3rem;
}
.blogs_link_box{
    display: flex;
    justify-content: center;
    align-items: center;
    text-underline-offset: 7px;
    margin-bottom: 1.5rem;
    text-align: center;
}
.blogs_link_box a{
    color: rgb(0, 125, 193);
    font-size: 1.2rem;
}
@media(max-width: 767px) {
    .homepage_sec_one_row{
        padding: 0% 10%;
    }
   .popular_products h5{
    font-size: 1.5rem;
    /* text-align: start; */
   }
   .popular_products p{
   
    /* text-align: justify; */
   }
   .popular_products_boxes_img img{
   border-bottom-left-radius: 0%;
   border-bottom-right-radius: 0%;
   } 
    .popular_products_smlscrn{
        display: block;
        margin-top: 1rem;
    }
    .popular_products_boxes_smlscrn{
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        padding: 0 10%;
    }
    .popular_products_shop{
        padding-left: 5%;
    }
    .popular_products_content{
        box-shadow: none;
    }
    .popular_products_content h5{
        font-size: 1rem;
    }
    .howitwrks_tellus{
        padding-left: 2%;
        gap: 2rem;
    }
    .howitwrks_tellus_p{
        font-size: .8rem;
    }
    .howitwrks_tellus button{
        padding: 5px 30px;
        font-size: .7rem;
    }
    .hw_it_wrk_carouse_img{
        /* height: 270px; */
        height: auto;
    }
    .blogs_link_box a{
        font-size: 1rem;
    }
}