.home_survey_main {
    padding: 0% 10%;
    margin-top: 3.5rem;
}

.survey_box_wrapper {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.survey_box {
    display: flex;
    justify-content: center;
    gap: 8%;
}

.survey_sm_box {
    width: 50%;
}

.survey_heading_box {
    display: flex;
    justify-content: flex-start;
    gap: 3%;
    margin-bottom: 1rem;
}

.survey_sm_box h2 {
    font-size: 1.4rem;
    font-weight: bold;
    width: 82%;
}

.img_icon {
    width: 15%;
}

.img_icon img {
    width: 90%;
    mix-blend-mode: multiply;
}

@media(max-width: 767px) {
    /* .home_survey_main{
        padding: 0% 5%;
      } */
      .survey_heading_box{
        align-items: center;
      }
    .survey_box {
        flex-direction: column;
        gap: 1rem;
    }

    .survey_sm_box {
        width: 100%;
    }

    .survey_sm_box h2 {
        font-size: 1.1rem;
    }

    .survey_sm_box p {
        font-size: 1rem;
        text-align: justify;
    }
}