.indi_temp_container {
    width: 100%;
    padding: 0% 5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 8rem;
    margin-bottom: 2rem;
}

.indi_temp_text-section {
   width: 48%;
   margin-top: 2rem;
   text-align: justify;
}
.indi_temp_text-section h1{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: start;
}
.indi_temp_image-section {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    width: 48%;
}

.indi_temp_image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.indi_temp_image-section .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 10px;
}

.indi_temp_image-section:hover .overlay {
    opacity: 1;
}
.template-btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
}

.template-btn a,
.template-btn button {
    font-size: 14px;
    color: #fff;
    background-color: #009444;
    display: inline-block;
    /* height: auto !important; */
    text-transform: uppercase;
    font-weight: 500;
    word-spacing: 1px;
    letter-spacing: 1px;
    padding: 10px 50px;
    border-radius: 5px;
    /* margin-top: 20px; */
}

.template-btn a:hover {
    background-color: #13ac36;
}

.template-btn button {
    background-color: #ffffff;
    color: black;
}
.template-btn-hwitwrks a,
.template-btn-hwitwrks button {
    font-size: 14px;
    color: #fff;
    background-color: #009444;
    display: inline-block;
    /* height: auto !important; */
    text-transform: uppercase;
    font-weight: 500;
    word-spacing: 1px;
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 5px;
    margin-top: 20px;
}
.template_accordian{
    margin-top: 3rem;
    /* padding: 0% 2%; */
    padding: 0% 5%;
}
.template_accordian h1{
    font-weight: bolder;
    font-size: 2rem;
    /* text-align: center; */
    margin-bottom: 2rem;
}
.temp_accordian_holder{
    display: flex;
    /* justify-content: center;
    align-items: center; */
}
.temp_accordian{
    /* width: 80%;  */
    width: 90%; 
}
.template_below_box{
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 0% 5%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.template_below_box h1{
    font-weight: bolder;
    font-size: 2.3rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
    /* text-align: center; */
}
.template_below_textbox{
    width: 90%;
    /* margin:0 9%; */
}
.template_below_textbox h3{
    font-size: 1.4rem;
    font-weight: bolder;
    margin-bottom: .5rem;
}
.template_last_box{
    padding: 0% 5%;
    margin-top: 5rem;
}
.template_last_box h1{
    font-weight: bolder;
    font-size: 2rem;
    /* text-align: center; */
    margin-bottom: 1rem;
}
.template_last_box p{
    /* text-align: center; */
}
@media (max-width: 1140px) {
    .indi_temp_text-section h1{
        text-align: start;
     }
}
@media (max-width: 965px) {
     .indi_temp_container{
        flex-direction: column;
        gap: 2rem;
     }
     .indi_temp_text-section, .indi_temp_image-section {
        width: 100%;
     }
}

@media (max-width: 450px) {

    .template-btn-container {
        flex-direction: column;
        gap: 0;
        align-items: start;
    }
    .template_below_box h1{
        font-size: 2rem;
    }
    .temp_accordian{
        width: 100%; 
      }
}
