.homepage_solution_container{
    padding-top:3rem;
    padding-bottom: 3rem;
    background:url("/src/images/homepage_solution.png")center/cover;
    margin-top: 3rem;
}
.homepage_solution_heading{
    text-align: center;
  }
  .homepage_solution_heading h5{
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
  }
.homepage_solution{
    /* padding: 2% 10%; */
    padding: 2% 5%;
    justify-content: center;
    gap: 1rem;
}
.homepage_solution_box{
    padding-top: 1rem;
    border-radius: 5px;
    background-color: white;
    border: 1px solid rgb(219, 215, 215);
    transition: .5s;
    overflow: hidden;
    width: 25%;
}
.homepage_solution_box:hover{
   transform: scale(1.04);
}
.homepage_solution_box a{
    text-decoration: none;
    color: black;
}
.homepage_solution_img,.homepage_solution_text{
    display: flex;
    justify-content: center;
    align-items: center;
}
.homepage_solution_img img, .homepage_solution_text div{
    width: 90%;
}
.homepage_solution_img img{
    height:180px ;
    /* object-fit: cover; */
}
.homepage_solution_text{
    margin-top: 1rem;
}
.homepage_solution_text p{
    /* height: 6vh;
    overflow: hidden; */
    font-size: 14px;
    padding-bottom: 3px;
}
.homepage_solution_text span{
    font-size: 14px;
    color: #007dc1;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 5px;
}

@media(max-width: 767px) {
    .homepage_solution {
        flex-direction: column;
    }
    .homepage_solution_img img{
        height:auto;
        /* object-fit: cover; */
    }
    .homepage_solution_box{
        width: 100%;
    }
}