.nps_container {
    width: 100%;
    margin-top: 5rem;
    position: relative;
  }
  
  .nps_background_image {
    position: relative;
    width: 100%;
    height: 250px;
    color: white; /* Adjust text color for better readability on background */
    overflow: hidden;
  }
  
  .nps_background_image::before {
    content: "";
    background-image: url("../images/Artificial_Intelligence.avif");
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    z-index: -1; 
  }
  
  .nps_text_section {
    padding: 3rem;
    padding-left:5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
    position: relative;
    z-index: 1; /* Ensure the text stays above the blurred background */
  }
  
  .nps_text_section h1 {
    font-size: 3.5rem;
    font-weight: bolder;
  }
  
  .nps_text_section h5 {
    font-size: 2rem;
  }
  
  @media (max-width:635px){
    .nps_text_section h1 {
      font-size: 2.8rem;
      font-weight: bolder;
    }
    
    .nps_text_section h5 {
      font-size: 1.5rem;
    }
  }
  @media (max-width:520px){
    .nps_text_section h1 {
      font-size: 2.5rem;
      font-weight: bolder;
    }
    
    .nps_text_section h5 {
      font-size: 1.2rem;
    }
 .template_accordian ul{
      padding-left: 5%;
      padding-right: 5%;
      
    }
    .template_accordian ul li{
      text-align: justify;
    }
  
  }
  @media (max-width:350px){
    .nps_text_section h1 {
      font-size: 1.8rem;
      font-weight: bolder;
    }
    
    .nps_text_section h5 {
      font-size: 1rem;
    }
    .template_accordian h1 {
      font-size: 1.5rem;
    }
  }