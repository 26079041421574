.survey_more_box_wrapper{
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.survey_more_main_container >h4{
font-weight: bold;
margin-bottom: 2rem;
}
.survey_more_box{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5%;
   width: 100%;
}
.survey_more_img_box,.survey_more_sm_box{
    width: 47%;
}
.survey_more_sm_box  h6{
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 1rem;
}
.survey_more_img_box p{
    margin-top: 1rem;
    text-align: justify;
}
.survey_more_img_box img{
    width: 100%;
    height: 200px;
}
.survey-more_list_box ol> li{
    font-weight: bold;
}
.survey-more_list_box ul li {
    list-style:circle;
    font-size: 14px;
    text-align: justify;
}
@media(max-width: 767px) {
    .howit_works h5{
     font-size: 1.5rem;
    }
    .survey_more_box{
      flex-direction: column-reverse;
    }
    .reverse_box{
        flex-direction: column;
    }
    .survey_more_img_box,.survey_more_sm_box{
        width: 100%;
    }
    .survey_more_main_container >h4 {
        font-size: 1.3rem;
    }
    .survey_more_sm_box h6 {
        font-size: 1.1rem;
    }
    .survey-more_list_box ol> li {
        font-size: .9rem;
    }
    .survey-more_list_box ol{
        padding-left: 0;
    }
 }