.shimmerHead{
  width: 100%;
  height: 15vh;
  background-color: rgb(227, 222, 222); 
}
.shimmerContainer{
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 3rem;
    margin-left: 5rem;
  }
  
  .cardShimmer {
    width: 22%;
    height: 300px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .cardShimmerUp {
    height: 150px;
    width: 100%;
    background-color: rgb(227, 222, 222);
    border-radius: 10px;
  }
  
  .shimmerLineOne {
    margin: 2rem 1rem;
    height: 10px;
    width: 30%;
    background-color: rgb(227, 222, 222);
  }
  
  .shimmerLineTwo {
    margin: 1rem 1rem;
    height: 10px;
    width: 60%;
    background-color: rgb(227, 222, 222);
  }
  
  .shimmerLineThree {
    margin: 1rem 1rem;
    height: 10px;
    width: 80%;
    background-color: rgb(227, 222, 222);
  }

  .cardShimmerSmall{
    width: 40%;
    height: 15vh;
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: rgb(227, 222, 222);
  }
  .shimmer_product{
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    padding: 2rem;
    margin-left: 4rem;
  }
  .shimmer_left,.shimmer_right{
    height: 40vh;
    background-color: rgb(227, 222, 222);
    border-radius: 10px;
  }
  .shimmerContainertable{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: .5rem;
    margin-top: 3rem;
    margin-left: 2rem;
  }
  
  .cardShimmertable {
    width: 23%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .cardShimmerUptable {
    height: 70px;
    width: 100%;
    background-color: rgb(237, 233, 233);
    border-radius: 5px;
  }