.ques_sec_row_outer{
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-top: 1rem;
}
.ques_sec_row_main{
    width: 100%;
    background-color: #f5f5f5;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem;
    cursor: pointer;
   
}
.ques_sec_row_main:hover{
    background-color: #edecec;
}
.ques_sec_row_main_left,.ques_sec_row_main_right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    /* font-size: .8rem; */
}
.ques_sec_row_main_left span,.ques_sec_row_main_right span{
    font-weight: bold;
}
.proof_tag_btn{
   border: 1px solid #ccc;
    background-color: white;
    padding: .5rem 1rem;
    border-radius: 5px;
    /* font-size: .8rem; */
}
.proof_tag_btn:hover{
    background-color: #f5f5f5;
}
.ques_sec_row_thead{
    font-size: .9rem;
}
.ques_sec_row_tbody{
    font-size: .8rem;
}
.add_ques_btn_box{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
.input-focused {
    /* border-color: #007dc1 !important; */
    border: 2px solid #079ef0 !important;
  }
  .modal_inner_main{
    width: 100%;
    padding: 2rem 3rem;
    border: none;
    position: relative;
    overflow: scroll;
    /* max-height: 90vh; */
  }
  .modal_inner_main::-webkit-scrollbar {
    display: none; 
  }
  
  /* Hide scrollbar for Firefox */
  .modal_inner_main {
    -ms-overflow-style: none;  /* Hides scrollbar in IE and Edge */
    scrollbar-width: none;     /* Hides scrollbar in Firefox */
  }
  