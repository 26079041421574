.dash_report_select{
    width: 80%;
    padding: .5rem;
    outline: none;
    border: 2px solid #eee;
    border-radius: 10px;
    /* color: #aeadad; */
}
.report_date{
  margin-right: 1rem;
  margin-bottom: .5rem;
}
.report_date_select{
  width: 90%;
}
.dash_report_table{
    margin-top: 5rem;
    padding: 1rem;
}
.report_details_head_box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0rem;
}
.report_details_head_btn_container{
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.report_details_head_btn_container button{
    border: 1px solid #eee;
    outline: none;
    border-radius: 5px;
    background: #FEFEFE;
    padding: .3rem 1rem;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
    font-size: .9rem;
    font-weight: bold;
}
/* .report_details_result_box{
    border: 1px solid black;
} */
.report_details_result_box h6{
    background: linear-gradient(to right, #066497, #007DC1);
    padding: 1rem;
    color: white;
}
.report_details_result{
    padding: .5rem 1rem;
}
.report_details_chart_box{
    /* margin-left: 2rem; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
}
.report_details_score_box{
    margin-top: 3rem;
}
.report_details_score_box h5{
    margin-bottom: 2rem;
}
.report_details_score_table_head th{
    background-color: #007DC1;
    color: white;
    font-size: .9rem;
  }
  .report_details_score_table_body tr{
    border: 1px solid #eee;
  }
  .report_details_score_table_body a{
    text-decoration: none;
  }
.hide_view_btn{
    border: none;
    outline: none;
    background-color: white;
    color: blue;
}
  .expanded-content {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    color: red;
  }

  .tableactive{
    font-size: 1.1rem;
    font-weight: bold;
  }
  .tablenormal{
    font-size: 1rem;
  }
 .report_storelist_upper{
  display: flex;
  justify-content: space-between;
 }
 .modal_mobile{
  margin-top: '30px';
 }
 .report_storelist_heading{
  margin-top: 2rem;
  text-align: center;
  margin-bottom: 2rem;
 }
 .dash_report_select_btn{
  margin-top: 1.8rem;
 }
 .changepasswrs_loginbox{
  width:50%;
 }
 .back_red{
  background-color: red !important;
 }

 /* ---------------------------toggle switch------------------------ */

 .toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px; 
  height: 24px; 
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px; 
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px; 
  width: 18px; 
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.status {
  display: block;
  text-align: center;
  margin-top: 5px; 
  font-weight: bold;
  font-size: 12px;
}


@media (max-width: 750px) {
  .report_upperpart{
    margin-top: 1rem;
  }
  .registration_form_content, .dash_report_select, .report_date_select{
    width: 100%; 
  }
  .report_date{
    margin-top: 1rem;
  }
  .dash_report_table {
    margin-top: 2rem;
  }
  .report_storelist_upper{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
   }
   .modal_mobile{
    z-index: 20000;
    margin-top: 5rem;
   }
   .table_border_head_green{
    margin-top: 1.5rem;
   }
   .table_border_head_green tr,.table_border_head_green td , .report_table_mobile tr,.report_table_mobile td {
    font-size: .8rem;
   }
   .report_storelist_heading{
    margin-bottom: 0rem;
   }
   .store_report_table_mobile td{
    padding: .5rem 0rem;
   }
   .dash_report_select_btn{
    margin-top: 1rem;
   }
   .changepasswrs_loginbox{
    width:90%;
   }
}
