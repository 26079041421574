.casestudy_box {
    transition: transform 0.5s;
    padding: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
    border: 1px solid rgba(0, 0, 0, .125);
}

.casestudy_box:hover {
    transform: scale(1.03);
}

.casestudy_box a {
    text-decoration: none;
    color: black;
}

.casestudy_tab_box p {
    cursor: pointer;
}

.casestudy_tab_box p:hover {
    color: #007DC1;
}
.casestudy_brand_text{
    height: 240px;
}
.casestudy_brand_text p{
    height: 130px;
    border-bottom: none;
}
/* ----------------------------------------------------------------------------------------------------------------- */
.manyavar_heading_text {
    display: flex;
    flex-direction: column;
    align-items: center;
  padding: 1% 3% 0% 3%;
}
.manyavar_heading_text span{
    /* text-align: center; */
    text-align: justify;
}
.manyavar_heading{
color: #007DC1;
font-weight: bold;
padding: 1% 3% 1% 3%;
/* text-align: center; */
text-align: justify;

}
.manyavar_heading_next_box{
    margin: 5rem 5rem 3rem 5rem;
}
@media(max-width:1150px) {
    .manyavar_heading_text span {
        font-size: 1rem;
    }
}

@media(max-width:945px) {
    .manyavar_heading_text {
        padding: 0rem 1rem;
    }
}
@media (min-width:767px) and (max-width:1200px){
    .casestudy_box_container{
        padding: 2%;
    }
}
@media (max-width: 600px) {
    /* .lead {
        font-size: 14px;
    } */

    .img-float {
        margin: 0;
    }

    .card-title {
        font-size: 18px;
    }

    .card-text {
        font-size: 14px;
    }

    .manyavar_heading_text span {
        font-size: .9rem;
        text-align: justify;
    }
    .manyavar_heading {
        padding: 1rem;
        padding-bottom: 0rem;
        font-size: 1rem;
        text-align: justify;
    }
    .manyavar_heading_next_box{
        margin: 2rem;
    }
}

@media (max-width: 500px) {
    /* .casestudy_brand_text{
        height: auto;
    } */
    .casestudy_brand_text p{
        height: auto;
        border-bottom: none;
    }
}