.logout_dropdown {
    width: 150px;
    border: 1px solid rgb(203, 201, 201);
    background-color: white;
    position: absolute;
    top: 1.7rem;
    left: -10px;
    top: 35px;
    /* left: -90px; */
    border-radius: 10px;
    padding: .8rem;
    visibility: hidden;
}

.logout_dropdown a {
    text-decoration: none;
    color: black;
}

.head_dropdown:hover>ul {
    visibility: visible;
}

.logout_dropdown .dropdown_list {
    font-weight: 600;
    margin-bottom: .5rem;
    font-size: .9rem;
    cursor: pointer;
}


.deflt_dash_head {
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    border-bottom: 2px solid #eee;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    padding-left: 1rem;
}

.deflt_dash_head img {
    width: 100px;
}

.hide_btn_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 2rem;
}

.deflt_dash_stepper {
    padding-left: 1.5rem;
    border-bottom: 2px solid #eee;
}
.deflt_dash_stepper_heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.deflt_dash_stepper_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding: 0rem 2rem;
    margin: 2rem auto;
    margin-bottom: 3rem;
    border-radius: 10px;
    background: #FEFEFE;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
}
.hide_btn_container{
    font-size: .9rem;
}
/* ***************************************************************************************** */
.dash_project_summary {
    padding: 0rem 2rem;
    padding-top: 2rem;
    margin-bottom: 2rem;
    border-bottom: 2px solid #eee;
}

.dash_project_summary .summary_box_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    margin: 2rem 0rem;
}

.summary_box_container .summary_box {
    width: 20%;
    padding: 1rem;
    border-radius: 10px;
    background: #FEFEFE;
    box-shadow: 2px 4px 10px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* ******************************************************************************************** */
.dash_project_charts_holder {
    padding: 0rem 2rem;
    border-bottom: 2px solid #eee;
    margin-bottom: 2rem;
}

.project_charts_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.project_charts_head select {
    width: 25%;
    padding: .5rem;
    outline: none;
    border: 2px solid #eee;
    border-radius: 10px;
    color: #aeadad;
}

.project_charts_head select option {
    color: #aeadad;
}

.dash_project_charts_container {
    height: 20vh;
}

/* --------------------------------------------------------------------------------------- */
.strt_project_container{
    position: fixed;
    right: 2rem;
    bottom: 1rem;
    z-index: 100;
    animation: moveUpDown 2s ease-in-out infinite;
}
/* ------------------------------------------------------------------------------------ */
.getStarted{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30vh;
}
.getStarted h1:hover{
    color: rgb(22, 175, 22);
}
@keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px); 
    }
  }
.dash_side_list_first {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #007DC1;
    color: white;
}

@keyframes up_down {
    0% {
        bottom: 0rem;
    }
    50% {
        bottom: 5rem;
    }
    100% {
        bottom: 0rem;
    }
}
.dash_side_list_first:hover {
    border: 2px solid #007DC1;
    background-color: white;
    color: #007DC1;
}

.dash_side_list_first span {
    border-radius: 50%;
    border: 2px solid white;
    padding: .5rem .7rem;
}

.dash_side_list_first:hover span {
    border: 2px solid #007DC1;
}

.dash_side_list_first .fa-plus {
    color: white;
    font-size: 1.5rem;
}

.dash_side_list_first:hover .fa-plus {
    color: #007DC1;
}
.dashbodymain{
    padding: 2rem;
    /* padding-top: 5rem; */
    padding-top: 2rem;
}

/* ************************************************************************************** */

.loading_cell_small,.deflt_dash_stepper_container_mobile{
    display: none;

}

/* ***************************************************************************************/
@media (max-width: 1044px){
    .deflt_dash_head{
       padding-left: 0rem;
       gap: .5rem;
    }
    .dashbodymain{
        padding-left: .5rem;
    }
}
@media (max-width: 870px){
    .deflt_dash_stepper_container{
        display: none;
    }
    .deflt_dash_stepper_container_mobile{
        display: block;
    }
    .deflt_dash_stepper_heading{
        flex-direction: column;
    }
    .deflt_dash_stepper_heading h4{
        font-size: 1.2rem;
    }
    .hide_btn_container{
        font-size: .8rem;
    }
}
@media (max-width: 750px) {
    .deflt_dash_head {
      flex-direction: column;
      text-align: center;
    }
  
    .deflt_dash_head img {
      width: 100px; 
    }
    .hide_btn_container {
      justify-content: center;
      padding-right: 0;
    }
  
    .deflt_dash_stepper {
      padding-left: 0;
    }
  
    .deflt_dash_stepper_container {
      padding: 0 1rem; /* Adjust padding as needed */
      margin-bottom: 2rem;
    }
    /* .dash_project_summary{
        margin-top: 2rem;
      } */
      .dash_project_summary .summary_box_container {
      flex-direction: column;
      gap: 1rem;
      margin: 1rem 0;
    }
  
    .summary_box_container .summary_box {
      width: 100%; /* Full width on smaller screens */
    }
  
    .project_charts_head {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
  
    .project_charts_head select {
      width: 100%; 
      margin-top: 1rem;
    }
  
    .dash_project_charts_holder {
      padding: 0 1rem; 
      margin-bottom: 1rem;
    }
  
    .dashbodymain {
      padding: 1rem; 
    }
  
    .strt_project_container {
      right: 1rem; 
      bottom: 1rem;
    }
   
  }
  @media (max-width: 500px) {
    .loading_cell_big{
        display: none;
    }
    .loading_cell_small{
        display: block;
    }
   
  }
  